import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Resource, Paragraph, List } from 'components/Landing/Resource/Resource'
import styled from 'styled-components'
import { colors, fontWeights } from 'services/theme'

const Donate: React.FC<RouteComponentProps> = () => {
  return (
    <Resource title='Donations'>
      <Paragraph>
        I don&apos;t think I deserve this, but some people asked me to add an
        option to donate.
      </Paragraph>
      <Paragraph>
        This app is being developed and maintained by a solo developer.
        Maintenance is not free - I have to pay for a domain, servers and store
        fees. I also spend countless hours on development and bug fixing. If you
        want to support the app, please donate using one of the options below. I
        appreciate your help a lot!
      </Paragraph>
      <List>
        <li>
          Monero:{' '}
          <External href='https://api.qrserver.com/v1/create-qr-code/?data=43Z3CXz9Sc9SbGcEzXs2eBfnJpB4gtQsFGanmnHfQWUFFbMn196sR9kVUAc313EfZ89yT4WjKZVZ1Q1ba8M3ioTfNfhevzu&size=220x220&margin=20'>
            43Z3CXz9Sc9SbGcEzXs2eBfnJpB4gtQsFGanmnHfQWUFFbMn196sR9kVUAc313EfZ89yT4WjKZVZ1Q1ba8M3ioTfNfhevzu
          </External>
        </li>

        <li>
          Bitcoin:{' '}
          <External href='https://api.qrserver.com/v1/create-qr-code/?data=bitcoin%3A1ChjgFMU2iHBK1DZQ2NxTbkQA97JJBHESY&size=220x220&margin=20'>
            1ChjgFMU2iHBK1DZQ2NxTbkQA97JJBHESY
          </External>
        </li>
        <li>
          Bitcoin Cash:{' '}
          <External href='https://api.qrserver.com/v1/create-qr-code/?data=bitcoincash%3Aqzq9mfv5ym7yxdyaxw4zvu4l3jzuf73xz5p4klcza3&size=220x220&margin=20'>
            qzq9mfv5ym7yxdyaxw4zvu4l3jzuf73xz5p4klcza3
          </External>
        </li>
        <li>
          Ethereum:{' '}
          <External href='https://api.qrserver.com/v1/create-qr-code/?data=ethereum%3A0x43c457D7924322b6DA78b91A5556b9E8c067379A&size=220x220&margin=20'>
            0x43c457D7924322b6DA78b91A5556b9E8c067379A
          </External>
        </li>
        <li>
          PayPal: <External href='https://paypal.me/jabczyk' />
        </li>
        <li>
          Something different?{' '}
          <A href='mailto:contact@doseapp.io'>contact@doseapp.io</A>
        </li>
      </List>
    </Resource>
  )
}

export default Donate

interface ExternalProps {
  href: string
}

const External: React.FC<ExternalProps> = ({ children, href }) => (
  <A href={href} target='_blank' rel='noopener noreferrer'>
    {children || href.split('://')[1]}
  </A>
)

const A = styled.a`
  color: ${colors.text};
  font-weight: ${fontWeights.medium};
  word-break: break-all;
`
